import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';

import './service-desc.scss'

function ServiceDesc(props) {
    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.service-desc-text',
                translateX: ['-150%', 0],
                direction: 'reverse',
                easing: 'easeInOutSine'
            })
            .add({
                targets: '.service-desc-image',
                opacity: [0, 1],
                easing: 'linear',
                duration: 500
            });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <section className="service-desc-container">
                <div className="service-desc-text-container">
                    <p className="service-desc-text">{props.text}</p>
                </div>
                <div className="service-desc-image-container">
                    <picture className="service-desc-image">
                        <img src={props.iconSrc} alt="illustration"/>
                    </picture>
                </div>
            </section>
        </Waypoint>
    )
}

ServiceDesc.propTypes = {
    text: PropTypes.string,
    iconSrc: PropTypes.string
}

ServiceDesc.defaultProps = {
    text: ``,
    iconSrc: ``
}
export default ServiceDesc

