import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./banner-with-img.scss"

function BannerWithImg(props) {

    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.banner-with-img-desc',
                opacity: [0, 1],
                delay: 500,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <div className="banner-with-img-container" style={{'--image-url': `url(${props.urlImage})`}} >
                <picture className="banner-with-img-picture">
                    <img src={props.urlImage} alt="background illustration"/>
                </picture>
                <h1 className="banner-with-img-desc">{props.description}</h1>
            </div>
        </Waypoint>
    )
}

BannerWithImg.propTypes = {
    description: PropTypes.string,
    urlImage: PropTypes.string,
}

BannerWithImg.defaultProps = {
    description: ``,
    urlImage: ``
}

export default BannerWithImg