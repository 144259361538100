import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import ContactBriefly from "../components/shared/contact-briefly/contact-briefly";
import BannerWithImg from "../components/shared/banner-with-img/banner-with-img";
import ServiceDesc from "../components/services/service-desc/service-desc";

import consultingBannerImg from "../images/banners-img/consulting-banner-img-tiny.jpg"
import consultingIcon from "../images/icons/consulting.svg"

const ServicesConsultingPage = () => {

    return (
        <Layout>
            <SEO title="Consultoría Informática" description="Transforme su estrategia de negocio en productos y servicios tecnológicamente viables. Asesoría informática Costa Rica." lang="es" path="consultoria-informatica/" meta={[
                {
					property: `og:type`,
					content: `website`,
                },
                {
                    property: `og:image`,
                    content: 'https://res.cloudinary.com/spheredevs/image/upload/v1600464750/SphereDevs/consulting-banner-img-tiny.jpg'
                },
                {
                    property: `og:locale`,
                    content: 'es_CR',
                },
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: "Consultoría Informática",
				},
				{
					name: `twitter:description`,
					content: "Transforme su estrategia de negocio en productos y servicios tecnológicamente viables. Asesoría informática Costa Rica.",
                },
                {
                    name: `twitter:creator`,
                    content: `@SphereDevs`
                },
                {
                    name: `twitter:site`,
                    content: `@SphereDevs`,
                }
            ]}/>
            <BannerWithImg urlImage="https://res.cloudinary.com/spheredevs/image/upload/v1600464750/SphereDevs/consulting-banner-img-tiny.jpg" description="Soluciones que se alinean con los objetivos de su negocio" />
            <ServiceDesc text="Brindamos asesoría para que pueda transformar sus estrategias de negocio en productos y servicios tecnológicamente viables. Buscamos generar valor utilizando sus recursos de manera eficiente y a través del uso adecuado de la tecnología." iconSrc={consultingIcon}/>
            <ContactBriefly description="¿Tienes algo en mente? No esperes más!" contactUsAction="/contacto" contactUsLabel="Iniciar proyecto" />
        </Layout>
    )
}

export default ServicesConsultingPage